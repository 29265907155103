import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import styles from './OrderFailureModal.scss';
import Button from '../Button';
import dataHooks from '../../data-hooks';
import { OrderFailureType, ErrorCodes, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { SetCheckoutStepPayload } from '../../../../state/checkout/checkout.actions.types';
import { CheckoutStep } from '../../../../core/types/Checkout';
import { IPaymentMethod } from '@wix/cashier-payments-widget';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';
import { LocaldeliveryOrderErrorCode as EstimateTpeErrorCode } from '@wix/ambassador-restaurants-local-delivery/http';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { getCTAAndContentByErrorCode } from './OrderFailureModalUtils';

export interface OrderFailureModalProps {
  onRequestClose: () => void;
  resetErrors: () => void;
  errorType?: OrderFailureType;
  errorCode?: number | string;
  estimateTpaErrorCode?: EstimateTpeErrorCode;
  isCouponError?: boolean;
  setCheckoutStep: (payload: SetCheckoutStepPayload) => void;
  hasFutureOrders?: boolean;
  restaurantPhone?: string;
  dispatchType: VirtualDispatchType;
  loyaltyPointsName?: string;
  paymentMethods: IPaymentMethod[];
  saveStateToSessionStorage: () => void;
}

const OrderFailureModal: React.FC<OrderFailureModalProps> = ({
  onRequestClose,
  errorType,
  errorCode,
  isCouponError,
  resetErrors,
  setCheckoutStep,
  hasFutureOrders,
  restaurantPhone,
  dispatchType,
  loyaltyPointsName,
  paymentMethods,
  estimateTpaErrorCode,
  saveStateToSessionStorage,
}) => {
  const { t } = useTranslation();
  const { displayableAmountLeft } = useMinOrderPriceDetails();

  const onCloseModal = (step?: CheckoutStep) => {
    step && setCheckoutStep({ step });
    onRequestClose();
    resetErrors();
  };

  /**
   * @deprecated errorType is depricated and should not be use. Instead use errorCode
   */
  const getCTA = (): { ctaText: string; onClick: () => void; title: string; details: string[] } => {
    switch (errorType) {
      case 'invalid_data':
        return {
          ctaText: t('error_modal_offline_back_button'),
          onClick: () => {
            onCloseModal();
            window.location.href = window.location.href.replace('checkout', '');
          },
          title: t('error_modal_unavailableitem_title'),
          details: [t('error_modal_unavailableitem_text')],
        };
      case 'out_of_stock':
        return {
          ctaText: t('error_modal_dish_outofstock_cta'),
          onClick: () => {
            window.location.href = window.location.href.replace('checkout', 'cart');
          },
          title: t('error_modal_dish_outofstock_title'),
          details: [t('error_modal_dish_outofstock_description')],
        };
      case 'no_server_response':
        return {
          onClick: onCloseModal,
          ctaText: t('error_modal_connection_CTA'),
          title: t('error_modal_connection_title'),
          details: [
            t('error_modal_connection_subtitle_1'),
            t('error_modal_connection_subtitle_2', { phone: restaurantPhone }),
          ],
        };
      case 'invalid_cc_number':
      case 'cc_expired':
      case 'cc_rejected':
        return {
          ctaText: t('error_modal_verifycard_back_button'),
          onClick: () => {
            onCloseModal('payments');
          },
          title: t('error_modal_verifycard_title'),
          details: [t('error_modal_verifycard_text')],
        };
      case 'address_not_in_range':
        return {
          ctaText: t('error_modal_delivery_area_change_cta'),
          onClick: () => {
            onCloseModal();
            window.location.href = window.location.href.replace('checkout', '');
          },
          title: t('error_modal_delivery_area_change_title'),
          details: [t('error_modal_delivery_area_change_description')],
        };
      case 'unavailable':
      case 'cannot_submit_order':
        return {
          title: t('error_modal_title'),
          details: [t('error_modal_subtitle_1'), t('error_modal_subtitle_2')],
          ctaText: t('error_modal_CTA'),
          onClick: () => {
            onCloseModal('address-information');
            saveStateToSessionStorage();
            window.location.reload();
          },
        };
      case 'internal':
        return {
          ctaText: t('error_modal_CTA'),
          onClick: onCloseModal,
          title: t('error_modal_servererror_title'),
          details: [t('error_modal_servererror_text')],
        };
      default:
        return {
          ctaText: t('error_modal_CTA'),
          onClick: onCloseModal,
          title: t('error_modal_title'),
          details: [t('error_modal_subtitle_1'), t('error_modal_subtitle_2')],
        };
    }
  };

  const { ctaText, onClick, title, details = [] } =
    getCTAAndContentByErrorCode({
      errorCode,
      errorType,
      t,
      onCloseModal,
      saveStateToSessionStorage,
      hasFutureOrders,
      isCouponError,
      dispatchType,
      paymentMethods,
      loyaltyPointsName,
      estimateTpaErrorCode,
      displayableAmountLeft,
    }) ?? getCTA();

  const header = (
    <Text className={styles.header} typography="header-m" data-hook={dataHooks.orderFailureModalTitle}>
      {title}
    </Text>
  );
  const shouldShowFootnote =
    (errorType === 'invalid_cc_number' || errorType === 'cc_expired' || errorType === 'cc_rejected') &&
    errorCode !== ErrorCodes.TOGGLE_OFF_CASH;

  return (
    <ModalLayoutBasic header={header} onCloseClick={onClick} data-hook={dataHooks.orderFailureModal}>
      <div className={styles.wrapper}>
        <div data-hook={dataHooks.orderFailureModalSubtitle}>
          {details.map((line) => (
            <Text typography="p2-m" tagName="p" key={line}>
              {line}
            </Text>
          ))}
        </div>
        <Button className={styles.okButton} upgrade data-hook={dataHooks.orderFailureModalOkButton} onClick={onClick}>
          {ctaText}
        </Button>
        {shouldShowFootnote && (
          <Text
            typography="p2-xs"
            data-hook={dataHooks.orderFailureModalFooter}
            tagName="p"
            className={styles.subFooter}
          >
            {t('error_modal_verifycard_confirmnocharge_disclaimer')}
          </Text>
        )}
      </div>
    </ModalLayoutBasic>
  );
};

OrderFailureModal.displayName = 'OrderFailureModal';

export default OrderFailureModal;
