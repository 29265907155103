import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { getDisplayablePrice, Payment, Tip, YearMonth, TipPresets } from '@wix/restaurants-client-logic';
import { useBi, useExperiments, useTranslation } from 'yoshi-flow-editor-runtime';
import { CheckoutStep } from '../../../../core/types/Checkout';
import dataHooks from '../../data-hooks';
import { calculateTopValue } from '../Scroller/scrollingUtils';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import Cashier from '../Cashier';
import Scroller from '../Scroller';
import Text from '../../core-components/Text';
import {
  SetCashierPaymentPayload,
  SetPendingCashierPaymentPayload,
  SetTipPayload,
} from '../../../../state/checkout/checkout.actions.types';
import { ApiUnavailable, NotValidError, validateAndInitializePayment } from '../../../../core/logic/cashierLogic';
import { InitializePaymentResult } from '@wix/cashier-payments-widget';
import TipPicker from '../TipPicker/TipPicker';
import CheckoutPaymentsSummary from '../CheckoutPaymentsSummary';
import Button from '../Button';
import styles from './CheckoutPayments.scss';
import _ from 'lodash';
import { defaultTips, getTipValue } from '../TipPicker/TipPickerUtils';
import CurrencyUtils from '@wix/wixrest-utils/dist/CurrencyUtils';

function convertToPayload({ detailsId, cardPublicData }: InitializePaymentResult) {
  const result: SetCashierPaymentPayload = { paymentDetailsId: detailsId };

  if (cardPublicData) {
    result.creditCard = {
      network: cardPublicData.type,
      expiration: cardPublicData.expiration as YearMonth,
      lastDigits: cardPublicData.lastFourDigits || '',
    };
  }

  return result;
}

function convertCashierErrorToString(errorResult: NotValidError | ApiUnavailable) {
  let cashierStringError = errorResult.error;
  if ('invalidFields' in errorResult) {
    cashierStringError += `: ${errorResult.invalidFields.join(',')}`;
  }
  return cashierStringError;
}

export interface CheckoutPaymentsProps {
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  shouldDisplayTip: boolean;
  payments: Payment[];
  step: CheckoutStep;
  tip?: Tip;
  isLoading?: boolean;
  currency: string;
  locale: string;
  totalOrderPrice: number;
  tipCharge: number;
  tipPresets: TipPresets;
  paymentMethod: string | undefined;
  onEdit: () => void;
  onSubmit: () => void;
  setTip: (payload: SetTipPayload) => void;
  setCashierPayment: (payload: SetCashierPaymentPayload) => void;
  setPendingCashierPayment: (payload: SetPendingCashierPaymentPayload) => void;
  setCashierPaymentDone: () => void;
  describedby?: string;
  isByDeliveryPartner?: boolean;
  siteIsTemplate?: boolean;
  isMobile: boolean;
}

const CheckoutPayments: React.FC<CheckoutPaymentsProps> = ({
  done,
  collapsed,
  index = '0',
  shouldDisplayTip,
  payments,
  step,
  tip,
  isLoading,
  currency,
  locale,
  totalOrderPrice,
  tipCharge,
  paymentMethod,
  tipPresets,
  onEdit,
  onSubmit,
  setTip,
  setCashierPayment,
  setPendingCashierPayment,
  setCashierPaymentDone,
  describedby,
  isByDeliveryPartner,
  siteIsTemplate,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [keepCashierOpen, setKeepCashierOpen] = React.useState(false);
  const biLogger = useBi();
  const { experiments } = useExperiments();
  // if tip presets haven't been configured yet, they will be the default values
  tipPresets = _.isEmpty(tipPresets) ? defaultTips : tipPresets;
  // for tip bi events
  const selectedTipPresets =
    tipPresets.tipOption === 'percentageValues' ? tipPresets.percentageValues : tipPresets.currencyValues;
  const defaultTipValue = _.find(selectedTipPresets, (tipPreset) => tipPreset.isDefault === true)?.value;
  const defaultTipValueSelected =
    tip &&
    !tip.isCustom &&
    (tip.amount === defaultTipValue ||
      tip.amount / 100 === defaultTipValue ||
      (tip.amount === 0 && defaultTipValue === 'None'));
  const selectedTipValue = tip?.isCustom
    ? 'custom'
    : _.find(selectedTipPresets, (tipPreset) => {
        const tipValueToCompare = tip && (tipPresets.tipOption === 'percentageValues' ? tip.amount : tip.amount / 100);
        return tipPreset.value === tipValueToCompare || (tipPreset.value === 'None' && tip?.amount === 0);
      })?.value;
  const currencySymbol = CurrencyUtils.currencySymbol(currency);
  const templatesDemoExperimentEnabled = experiments.enabled('specs.restaurants.templatesDemo');
  const isTemplate = siteIsTemplate && templatesDemoExperimentEnabled;

  if (isSubmitting && step !== 'payments') {
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (done && !isMobile) {
      scroller.scrollTo('address-information', {
        smooth: 'easeInOutCubic',
        duration: 200,
        offset: -calculateTopValue(0),
      });
    }
  }, [done]);

  // There's an issue with the Cashier widget not updating addresses, so we must re-render it on each address update
  const renderCashier = step !== 'address-information' && !isTemplate;

  const titleId = `${dataHooks.checkoutPayments}-title`;
  const amount = getDisplayablePrice(totalOrderPrice, locale, currency).match(/[\d.,]+/g)?.[0] || '';
  return (
    <Scroller name="checkout-payments" condition={isMobile && !collapsed && !done}>
      <div
        className={styles.wrapper}
        data-hook={dataHooks.checkoutPayments}
        aria-labelledby={titleId}
        aria-describedby={describedby}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_payments_title')}
          done={done}
          collapsed={collapsed}
          index={index}
          onEdit={onEdit}
          editButtonDataHook={dataHooks.checkoutSummaryLineEditPayment}
          titleId={titleId}
        />
        {renderCashier && (
          <Cashier
            hidden={!keepCashierOpen && (done || collapsed)}
            paymentMethodChanged={(paymentMethodId, paymentMethodTitle) =>
              setPendingCashierPayment({ payment: { paymentMethod: paymentMethodId }, paymentMethodTitle })
            }
            amount={amount}
            onPaymentStart={(method: string) => {
              setKeepCashierOpen(true);
            }}
            onPaymentComplete={(method: string, result: any) => {
              setKeepCashierOpen(false);
            }}
          />
        )}

        {!done && !collapsed && <div className={styles.padder} />}

        {shouldDisplayTip && !done && !collapsed && (
          <TipPicker tip={tip} onTipChange={setTip} currency={currency} locale={locale} tipPresets={tipPresets} />
        )}

        {!done && !collapsed && (
          <Button
            autoFocus
            upgrade
            fullWidth
            priority={PRIORITY.primary}
            className={styles.button}
            data-hook={dataHooks.checkoutPaymentsContinue}
            onClick={() => {
              setIsSubmitting(true);
              !isTemplate
                ? validateAndInitializePayment(
                    (initializePaymentResult) => {
                      biLogger.paymentsInformationContinue({
                        currency,
                        paymentType: paymentMethod || '',
                        totalTip: Math.round(tipCharge),
                        totalOrderValue: Math.round(totalOrderPrice),
                        isDefaultTip: defaultTipValueSelected,
                        isCustomTip: tip?.isCustom,
                        tip_type: tip?.tipType,
                        tipValue1: getTipValue(selectedTipValue, currencySymbol, tip),
                      });
                      setCashierPayment(convertToPayload(initializePaymentResult));
                      onSubmit();
                    },
                    (error) => {
                      biLogger.paymentsInformationContinueValidationError({
                        errorReason: convertCashierErrorToString(error),
                      });
                      setCashierPaymentDone();
                      setIsSubmitting(false);
                      scroller.scrollTo('checkout-payments', {
                        smooth: 'easeInOutCubic',
                        duration: 200,
                        offset: -calculateTopValue(0) - 36,
                      });
                    },
                  )
                : onSubmit();
            }}
            loading={isSubmitting || isLoading}
          >
            <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
          </Button>
        )}

        {done && <CheckoutPaymentsSummary payments={payments} />}
      </div>
    </Scroller>
  );
};

CheckoutPayments.displayName = 'CheckoutPayments';

export default CheckoutPayments;
