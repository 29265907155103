import { Code as posOrderErrorCode } from '@wix/ambassador-restaurants-posspihost-v1-tpa/types';
import { TranslationFunction } from 'yoshi-flow-editor-runtime';
import { OrderFailureType, ErrorCodes, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { CheckoutStep } from '../../../../core/types/Checkout';
import { IPaymentMethod } from '@wix/cashier-payments-widget';
import { LocaldeliveryOrderErrorCode as EstimateTpeErrorCode } from '@wix/ambassador-restaurants-local-delivery/http';

export interface CTAAndContentErrorCodeType {
  ctaText?: string;
  onClick: () => void;
  title?: string;
  details?: string[];
}

export const getCTAAndContentByPosErrorCode = ({
  errorCode,
  t,
  onCloseModal,
  saveStateToSessionStorage,
  hasFutureOrders,
}: {
  errorCode?: number | string;
  t: TranslationFunction;
  onCloseModal: (step?: CheckoutStep) => void;
  saveStateToSessionStorage: () => void;
  hasFutureOrders?: boolean;
}): CTAAndContentErrorCodeType | undefined => {
  let ctaText = '',
    title = '',
    details: string[] = [];

  const moveToMenusPage = () => {
    onCloseModal();
    window.location.href = window.location.href.replace('checkout', '');
  };
  const refreshCheckout = (step?: CheckoutStep) => () => {
    onCloseModal(step);
    saveStateToSessionStorage();
    window.location.reload();
  };
  let onClick = refreshCheckout('payments');

  switch (errorCode) {
    case posOrderErrorCode.PROMO_NOT_RECOGNIZED:
      title = t('POS.integration.uou.error_promo_not_recognized_header', 'Check your promo code');
      details = [t('POS.integration.uou.error_promo_not_recognized_body', "This promo code can't be applied.")];
      ctaText = t('POS.integration.uou.error_promo_not_recognized_cta', 'OK');
      break;
    case posOrderErrorCode.PROMO_USER_INELIGIBLE:
      title = t('POS.integration.uou.error_promo_user_ineligible_header', 'Check your promo code');
      details = [
        t('POS.integration.uou.error_promo_user_ineligible_body', "This promo code can't be applied to your order."),
      ];
      ctaText = t('POS.integration.uou.error_promo_user_ineligible_cta', 'OK');
      break;
    case posOrderErrorCode.PROMO_ALREADY_USED:
      title = t('POS.integration.uou.error_promo_already_used_header', 'Check your promo code');
      details = [
        t(
          'POS.integration.uou.error_promo_already_used_body',
          "This promo code has reached its limit and can't be applied.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_promo_already_used_cta', 'OK');
      break;
    case posOrderErrorCode.PROMO_EXPIRED:
      title = t('POS.integration.uou.error_promo_expired_header', 'Check your promo code');
      details = [t('POS.integration.uou.error_promo_expired_body', 'This promo code has expired.')];
      ctaText = t('POS.integration.uou.error_promo_expired_cta', 'OK');
      break;
    case posOrderErrorCode.DISCOUNT:
      title = t('POS.integration.uou.error_discount_header', 'Check your disocunt');
      details = [
        t(
          'POS.integration.uou.error_discount_body',
          "We can't apply this discount. Please try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_discount_cta', 'OK');
      break;
    case posOrderErrorCode.LOYALTY:
      title = t('POS.integration.uou.error_loyalty_header', 'Check your loyalty disocunt');
      details = [
        t(
          'POS.integration.uou.error_loyalty_body',
          "We can't apply the loyalty reward. Please try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_loyalty_cta', 'OK');
      break;
    case posOrderErrorCode.OUT_OF_SERVICE_AREA:
      title = t('POS.integration.uou.error_out_of_service_area_header', 'Delivery area unavailable');
      details = [
        t('POS.integration.uou.error_out_of_service_area_body', "Unfortunately we can't deliver to your location."),
      ];
      ctaText = t('POS.integration.uou.error_out_of_service_area_cta', 'OK');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.AVAILABILITY_CHANGED: // itemName is missing
      title = t('POS.integration.uou.error_availability_changed_header', 'Item unavailable');
      details = [
        t('POS.integration.uou.error_availability_changed_body', {
          defaultValue: '{{itemName}} is unavailable at the moment.',
          itemName: 'Item',
        }),
      ];
      ctaText = t('POS.integration.uou.error_availability_changed_cta', 'OK');
      break;
    case posOrderErrorCode.PRICE_CHANGED:
      title = t('POS.integration.uou.error_price_changed_header', 'Price update');
      details = [
        t(
          'POS.integration.uou.error_price_changed_body',
          "We can't accept your order because some prices have changed. Please contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_price_changed_cta', 'OK');
      break;
    case posOrderErrorCode.NO_CAPACITY:
      title = t('POS.integration.uou.error_no_capacity_header', 'Restaurant currently unavailable');
      details = [
        t('POS.integration.uou.error_no_capacity_body', "We can't process your order at the moment. Try again later."),
      ];
      ctaText = t('POS.integration.uou.error_no_capacity_cta', 'OK');
      onClick = refreshCheckout(hasFutureOrders ? 'address-information' : 'payments');
      break;
    case posOrderErrorCode.CLOSED:
      title = t('POS.integration.uou.error_closed_header', 'Restaurant closed');
      details = [t('POS.integration.uou.error_closed_body', "Sorry, we're closed at the moment.")];
      ctaText = t('POS.integration.uou.error_closed_cta', 'OK');
      onClick = refreshCheckout(hasFutureOrders ? 'address-information' : 'payments');
      break;
    case posOrderErrorCode.REQUIREMENTS_NOT_MET:
      title = t('POS.integration.uou.error_requirements_not_met_header', 'Add more items to cart');
      details = [
        t('POS.integration.uou.error_requirements_not_met_body', "You haven't reached the minimum order amount."),
      ];
      ctaText = t('POS.integration.uou.error_requirements_not_met_cta', 'OK');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.ORDER_TOTAL:
      title = t('POS.integration.uou.error_order_total_header', 'Issue with order total');
      details = [
        t(
          'POS.integration.uou.error_order_total_body',
          "We can't process your order. Contact the restaurant regarding your order price.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_order_total_cta', 'OK');
      break;
    case posOrderErrorCode.ORDER_ITEM:
      title = t('POS.integration.uou.error_order_item_header', 'Issue with order item');
      details = [
        t(
          'POS.integration.uou.error_order_item_body',
          "We can't process your order. Contact the restaurant regarding your order details.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_order_item_cta', 'OK');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.ORDER_REJECTED:
      title = t('POS.integration.uou.error_order_rejected_header', 'Issue with your order');
      details = [
        t(
          'POS.integration.uou.error_order_rejected_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_order_rejected_cta', 'OK');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.NONE:
    case posOrderErrorCode.OTHER:
      title = t('POS.integration.uou.error_other_header', 'Something went wrong');
      details = [
        t(
          'POS.integration.uou.error_other_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_other_cta', 'OK');
      break;
    case posOrderErrorCode.SYSTEM_ERROR:
      title = t('POS.integration.uou.error_system_error_header', 'Something went wrong');
      details = [
        t(
          'POS.integration.uou.error_system_error_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_system_error_cta', 'OK');
      break;
    case posOrderErrorCode.PAYMENT_FAILED:
      title = t('POS.integration.uou.error_payment_failed_header', 'Check your payment info');
      details = [
        t(
          'POS.integration.uou.error_payment_failed_body',
          "We couldn't process your payment. Try again or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_payment_failed_cta', 'OK');
      break;
    case posOrderErrorCode.CUSTOMER_PHONE_MISSING:
      title = t('POS.integration.uou.error_customer_phone_missing_header', 'Check your contact info');
      details = [
        t(
          'POS.integration.uou.error_customer_phone_missing_body',
          "We can't process your order without a valid phone number.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_customer_phone_missing_cta', 'OK');
      onClick = refreshCheckout('contact-information');
      break;
    case posOrderErrorCode.CUSTOMER_EMAIL_MISSING:
      title = t('POS.integration.uou.error_customer_email_missing_header', 'Check your contact info');
      details = [
        t(
          'POS.integration.uou.error_customer_email_missing_body',
          "We can't process your order without a valid email address.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_customer_email_missing_cta', 'OK');
      onClick = refreshCheckout('contact-information');
      break;
    case posOrderErrorCode.CUSTOMER_BLOCKED:
      title = t('POS.integration.uou.error_customer_blocked_header', 'Check your contact info');
      details = [
        t(
          'POS.integration.uou.error_customer_blocked_body',
          "We can't process your order. Contact the restaurant regarding your customer details.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_customer_blocked_cta', 'OK');
      onClick = refreshCheckout('contact-information');
      break;
    case posOrderErrorCode.ACCOUNT_NOT_FOUND:
      title = t('POS.integration.uou.error_account_not_found_header', 'Something went wrong');
      details = [
        t(
          'POS.integration.uou.error_account_not_found_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_account_not_found_cta', 'Close');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.ACCOUNT_NOT_AUTHORIZED:
      title = t('POS.integration.uou.error_account_not_authorized_header', 'Something went wrong');
      details = [
        t(
          'POS.integration.uou.error_account_not_authorized_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_account_not_authorized_cta', 'Close');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.LOCATION_NOT_FOUND:
      title = t('POS.integration.uou.error_location_not_found_header', 'Something went wrong');
      details = [
        t(
          'POS.integration.uou.error_location_not_found_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_location_not_found_cta', 'Close');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.ACCOUNT_NOT_CONNECTED:
      title = t('POS.integration.uou.error_account_not_connected_header', 'Something went wrong');
      details = [
        t(
          'POS.integration.uou.error_account_not_connected_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_account_not_connected_cta', 'Close');
      onClick = moveToMenusPage;
      break;
    case posOrderErrorCode.ACCOUNT_CONFIGURATION:
      title = t('POS.integration.uou.error_invalid_account_configuration_header', 'Something went wrong');
      details = [
        t(
          'POS.integration.uou.error_invalid_account_configuration_body',
          "We can't accept your order at the moment. Try again later or contact the restaurant.",
        ),
      ];
      ctaText = t('POS.integration.uou.error_invalid_account_configuration_cta', 'Close');
      onClick = moveToMenusPage;
      break;
    default:
      return undefined;
  }
  return { ctaText, details, title, onClick };
};

export const getCTAAndContentByErrorCode = ({
  errorCode,
  errorType,
  t,
  onCloseModal,
  saveStateToSessionStorage,
  hasFutureOrders,
  isCouponError,
  dispatchType,
  paymentMethods,
  loyaltyPointsName,
  estimateTpaErrorCode,
  displayableAmountLeft,
}: {
  errorCode?: number | string;
  errorType?: OrderFailureType;
  t: TranslationFunction;
  hasFutureOrders?: boolean;
  onCloseModal: (step?: CheckoutStep) => void;
  saveStateToSessionStorage: () => void;
  isCouponError?: boolean;
  dispatchType: VirtualDispatchType;
  loyaltyPointsName?: string;
  paymentMethods: IPaymentMethod[];
  estimateTpaErrorCode?: EstimateTpeErrorCode;
  displayableAmountLeft?: string;
}): CTAAndContentErrorCodeType | undefined => {
  let ctaText: string, title: string, details: string[];
  let onClick = () => {
    onCloseModal('address-information');
    saveStateToSessionStorage();
    window.location.reload();
  };

  if (errorType?.includes('pos_error')) {
    return getCTAAndContentByPosErrorCode({ errorCode, t, onCloseModal, saveStateToSessionStorage, hasFutureOrders });
  }

  switch (errorCode) {
    case ErrorCodes.RESTAURANTS_CLOSED:
      ctaText = hasFutureOrders ? t('error_modal_closed_future_cta') : t('error_modal_closed_cta');
      onClick = () => {
        onCloseModal();
        window.location.href = window.location.href.replace('checkout', '');
      };
      title = hasFutureOrders ? t('error_modal_closed_future_title') : t('error_modal_closed_title');
      details = hasFutureOrders ? [t('error_modal_closed_future_description')] : [t('error_modal_closed_description')];
      break;
    case ErrorCodes.VARIATION_PRICE_UPDATE:
    case ErrorCodes.PRICE_UPDATE:
      ctaText = t('error_modal_price_change_cta');
      title = t('error_modal_price_change_title');
      details = [t('error_modal_price_change_description')];
      break;
    case ErrorCodes.ITEM_UNAVAILABLE_FOR_FULFILLMENT_METHOD:
      ctaText = t('error_modal_dish_visibility_cta');
      title = t('error_modal_dish_visibility_title');
      details = [t('error_modal_dish_visibility_description')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', 'cart');
      };
      break;
    case ErrorCodes.PROMO_CODE_UPDATE:
    case ErrorCodes.DISCOUNT_UPDATE:
      ctaText = isCouponError ? t('error_modal_promo_change_cta') : t('error_modal_discount_change_cta');
      title = isCouponError ? t('error_modal_promo_change_title') : t('error_modal_discount_change_title');
      details = isCouponError
        ? [t('error_modal_promo_change_description')]
        : [t('error_modal_discount_change_description')];
      break;
    case ErrorCodes.PICKUP_UNAVAILABLE:
      ctaText = t('error_modal_pickup_change_cta');
      title = dispatchType === 'dine-in' ? t('error_modal_dinein_change_title') : t('error_modal_pickup_change_title');
      details =
        dispatchType === 'dine-in'
          ? [t('error_modal_dinein_change_description')]
          : [t('error_modal_pickup_change_description')];
      onClick = () => {
        onCloseModal();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.DINE_IN_UNAVAILABLE:
      ctaText = t('error_modal_pickup_change_cta');
      title = t('error_modal_dinein_change_title');
      details = [t('error_modal_dinein_change_description')];
      onClick = () => {
        onCloseModal();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.CURBSIDE_PICKUP_UNAVAILABLE:
      ctaText = t('checkout_main_curbsidepickup_modal_unavailable_cta');
      title = t('checkout_main_curbsidepickup_modal_unavailable_title');
      details = [t('checkout_main_curbsidepickup_modal_unavailable_text')];
      onClick = () => {
        onCloseModal();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.INVALID_MIN_ORDER_PRICE:
      ctaText = t('checkout_main_minorder_additems_modal_cta');
      title = t('checkout_main_minorder_additems_modal_title');
      details = [t('checkout_main_minorder_additems_modal_text', { amount: displayableAmountLeft })];

      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;

    case ErrorCodes.LOYALTY_MISSING_REQUIRED_FIELD:
      ctaText = t('Loyalty_Error_cta');
      title = t('Loyalty_Error_MissingField_title');
      details = [t('Loyalty_Error_MissingField_Text')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;

    case ErrorCodes.LOYALTY_INSUFFICIENT_BALANCE:
      ctaText = t('Loyalty_Error_cta');
      title = loyaltyPointsName
        ? t('Loyalty_Error_Points_NotEnough_title', { pointnameplural: loyaltyPointsName })
        : t('Loyalty_Error_Points_NotEnough_title_default');
      details = loyaltyPointsName
        ? [t('Loyalty_Error_Points_NotEnough_Text', { pointnameplural: loyaltyPointsName })]
        : [t('Loyalty_Error_Points_NotEnough_Text_default')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.LOYALTY_INVALID_POINTS_AMOUNT:
      ctaText = t('Loyalty_Error_cta');
      title = t('Loyalty_Error_InvalidPoints_title');
      details = [t('Loyalty_Error_InvalidPoints_Text')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.LOYALTY_REWARD_NOT_ACTIVE:
      ctaText = t('Loyalty_Error_cta');
      title = t('Loyalty_Error_RewardUnavailable_title');
      details = [t('Loyalty_Error_RewardUnavailable_Text')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.LOYALTY_UNSUPPORTED_DISCOUNT_TYPE:
      ctaText = t('Loyalty_Error_cta');
      title = t('Loyalty_Error_RewardUnavailable2_title');
      details = [t('Loyalty_Error_RewardUnavailable2_Text')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;

    case ErrorCodes.LOYALTY_POINTS_REQUIRED:
      ctaText = t('Loyalty_Error_cta');
      title = t('Loyalty_Error_MinPoints_Change_title');

      details = loyaltyPointsName
        ? [t('Loyalty_Error_MinPoints_Change_Text', { pointnameplural: loyaltyPointsName })]
        : [t('Loyalty_Error_MinPoints_Change_Text_default')];

      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.LOYALTY_UNEXPECTED_CHARGES_AMOUNT:
      ctaText = t('Loyalty_Error_cta');
      title = t('Loyalty_Error_UnexpectedCharge_title');
      details = [t('Loyalty_Error_UnexpectedCharge_Text')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;

    case ErrorCodes.TOGGLE_OFF_CHARGE:
      ctaText = isCouponError ? t('error_modal_promo_change_cta') : t('error_modal_discount_change_cta');
      title = isCouponError ? 'Promo code is unavailable' : 'Discount is unavailable';
      details = isCouponError
        ? [t('error_modal_promo_change_description')]
        : [t('error_modal_discount_change_description')];
      break;
    case ErrorCodes.DINE_IN_AND_CURBSIDE_HAVE_BEEN_SUBMITTED:
      ctaText = t('checkout_main_delivery_method_infoerror_cta');
      title = t('checkout_main_delivery_method_infoerror_title');
      details = [t('checkout_main_delivery_method_infoerror_text')];
      break;
    case ErrorCodes.DINE_IN_MISSING_LABEL_VALUE:
    case ErrorCodes.DINE_IN_MISSING_LABEL:
      ctaText = t('checkout_main_delivery_method_dinein_error_cta');
      title = t('checkout_main_delivery_method_dinein_error_title');
      details = [t('checkout_main_delivery_method_dinein_error_text')];
      break;
    case ErrorCodes.DELIVERY_UNAVAILABLE:
      ctaText = t('error_modal_delivery_unavailable_cta');
      title = t('error_modal_delivery_unavailable_title');
      details = [t('error_modal_delivery_unavailable_text')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.DELIVERY_TIME_IS_MORE_THEN_MAX_DELIVETY_DURATION:
    case ErrorCodes.DELIVERY_TIME_IS_LESS_THEN_DELIVETY_DURATION:
    case ErrorCodes.DELIVERY_TIME_CANNOT_PRECEDE:
      ctaText = t('error_modal_moretime_cta');
      title = t('error_modal_moretime_title');
      details = [t('error_modal_moretime_text')];
      onClick = () => {
        onCloseModal('address-information');
        saveStateToSessionStorage();
        window.location.href = window.location.href.replace('checkout', '');
      };
      break;
    case ErrorCodes.TOGGLE_OFF_CASH:
      const isOtherPaymentMethodAvailable =
        paymentMethods && paymentMethods.filter((paymentMethod) => paymentMethod.paymentMethod !== 'offline').length;
      ctaText = t('error_modal_payment_method_unavailable_cta');
      title = isOtherPaymentMethodAvailable
        ? t('error_modal_payment_method_unavailable_title')
        : t('error_modal_only_payment_method_unavailable_title');
      details = isOtherPaymentMethodAvailable
        ? [t('error_modal_payment_method_unavailable_description')]
        : [t('error_modal_only_payment_method_unavailable_description')];
      onClick = () => {
        if (isOtherPaymentMethodAvailable) {
          // we need the cashier widget to re-render
          onCloseModal('payments');
          window.location.reload();
        } else {
          onCloseModal('address-information');
          saveStateToSessionStorage();
          window.location.href = window.location.href.replace('checkout', '');
        }
      };
      break;

    case ErrorCodes.USER_PAYMENT_CANCELED:
      ctaText = t('error_modal_canceled_bit_cta');
      title = t('error_modal_canceled_bit_title');
      details = [t('error_modal_canceled_bit_description')];
      onClick = () => {
        onCloseModal('address-information');
      };
      break;
    case ErrorCodes.DELIVERY_ESTIMATION_REJECTED_BY_PROVIDER:
      const estimateDetails = getEstimateTpaErrorParamsForModal({ error: estimateTpaErrorCode, t });
      ctaText = t('checkout_deliveryintegration_errormsg_systemerror_cta');
      details = [estimateDetails];
      onClick = () => {
        onCloseModal('address-information');
      };
      title = t('checkout_deliveryintegration_errormsg_systemerror_title');
      break;
    case ErrorCodes.FEE_DOESNT_MATCH:
      details = [t('checkout_deliveryintegration_errormsg_updatedfee_text')];
      ctaText = t('checkout_deliveryintegration_errormsg_returntocheckout_cta');
      onClick = () => {
        onCloseModal();
      };
      title = t('checkout_deliveryintegration_errormsg_systemerror_title');
      break;
    default:
      return undefined;
  }
  return { ctaText, onClick, title, details };
};

export const getEstimateTpaErrorParamsForModal = ({
  error = EstimateTpeErrorCode.UNAVAILABLE_PICKUP_OR_DELIVERY_TIME,
  t,
}: {
  error?: EstimateTpeErrorCode;
  t: TranslationFunction;
}) => {
  switch (error) {
    case EstimateTpeErrorCode.INELIGIBLE_FOR_CASH:
      return t('checkout_deliveryintegration_errormsg_maxcash_text');
    case EstimateTpeErrorCode.MISSING_PICKUP_OR_DELIVERY_TIME:
      return t('checkout_deliveryintegration_errormsg_validtime_text');
    case EstimateTpeErrorCode.INVALID_DROPOFF_ADDRESS:
      return t('checkout_deliveryintegration_errormsg_invaliddropoff_text');
    case EstimateTpeErrorCode.INVALID_PICKUP_ADDRESS:
    case EstimateTpeErrorCode.PICKUP_ADDRESS_NOT_SERVICEABLE:
    case EstimateTpeErrorCode.SYSTEM_ERROR:
    case EstimateTpeErrorCode.DROPOFF_ADDRESS_NOT_SERVICEABLE:
    case EstimateTpeErrorCode.EMPTY_ORDER:
    case EstimateTpeErrorCode.INVALID_PHONE_NUMBER:
    case EstimateTpeErrorCode.INVALID_ORDER_TOTAL:
    case EstimateTpeErrorCode.UNAVAILABLE_PICKUP_OR_DELIVERY_TIME:
    case EstimateTpeErrorCode.CASH_UNAVAILABLE:
    case EstimateTpeErrorCode.ALCOHOL_NOT_PERMITTED:
    case EstimateTpeErrorCode.CONTACTLESS_UNAVAILABLE:
    case EstimateTpeErrorCode.INVALID_TIP:
    case EstimateTpeErrorCode.INVALID_WEIGHT_OR_VOLUME:
    case EstimateTpeErrorCode.INVALID_PRICE:
    default:
      return t('checkout_deliveryintegration_errormsg_systemerror_text');
  }
};
