import React from 'react';
import { Element } from 'react-scroll';
import { DisplayableSubMenu, MenuDisplayOptions, AlignmentOptions } from '@wix/restaurants-client-logic';
import Text from '../../core-components/Text';
import SectionView from './SectionView';
import dataHooks from '../../data-hooks';
import MenuViewEmptyState from '../MenuViewEmptyState';
import SectionTabs from '../SectionTabs';
import styles from './SubMenuView.scss';
import { ClickOnMenuItemPayload } from '../../../../state/cart/cart.actions.types';
import stylesParams from '../../stylesParams';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import MediaQueryWrapper from '../MediaQueryWrapper/MediaQueryWrapper';
import Sticky from '../Sticky';

interface SubMenuViewProps {
  displayableSubMenu: DisplayableSubMenu;
  isMobile?: boolean;
  layout?: 'side-by-side' | 'card';
  dishCardAlignment?: AlignmentOptions;
  menuHeaderAlignment?: AlignmentOptions;
  menuDisplayOptions?: MenuDisplayOptions;
  isRTL?: boolean;
  selectAddressOnDishClick?: boolean;
  clickOnMenuItem: (payload: ClickOnMenuItemPayload) => void;
  hasItemImages?: boolean;
}

const SubMenuView: React.FC<SubMenuViewProps> = ({
  displayableSubMenu,
  isMobile,
  layout,
  dishCardAlignment,
  menuHeaderAlignment = AlignmentOptions.EMPTY,
  menuDisplayOptions,
  isRTL,
  selectAddressOnDishClick,
  clickOnMenuItem,
  hasItemImages,
}) => {
  const hasSections = displayableSubMenu.sections.length > 0;
  const showMenuDescription = !menuDisplayOptions?.hideMenuDescription;

  const tpaStyles = useStyles();
  const isEditorX = tpaStyles.get(stylesParams.responsive);
  const sectionTabs = (
    <SectionTabs
      sections={displayableSubMenu.sections.map((v) => ({
        id: v.id,
        slug: v.slug,
        title: v.displayableTitle,
      }))}
      isRTL={isRTL}
      dataHook={dataHooks.sectionTabs(displayableSubMenu.id)}
      isMobile
      className={styles.sectionTabs}
    />
  );
  return (
    <Element name={displayableSubMenu.id} className={styles.wrapper}>
      <section id={`restaurants-orders-entity-${displayableSubMenu.id}`} tabIndex={-1}>
        <div className={`${styles.titlesWrapper} ${styles[menuHeaderAlignment]}`}>
          <Text
            typography="header-m"
            tagName="h2"
            data-hook={dataHooks.menusSubMenuTitle}
            fontSizeOverride="menu-title"
          >
            {displayableSubMenu.displayableTitle}
          </Text>
          {showMenuDescription && displayableSubMenu.displayableDescription && (
            <Text typography="p2-m" tagName="p" fontSizeOverride="menu-description" className={styles.description}>
              {displayableSubMenu.displayableDescription}
            </Text>
          )}
        </div>

        {hasSections ? (
          <div>
            {displayableSubMenu.sections.length > 1 && (
              <Sticky>
                {isEditorX ? <MediaQueryWrapper mobileOnly>{sectionTabs}</MediaQueryWrapper> : isMobile && sectionTabs}
              </Sticky>
            )}
            {displayableSubMenu.sections.map((displayableSection, i) => (
              <SectionView
                clickOnMenuItem={clickOnMenuItem}
                key={displayableSection.id}
                displayableSection={displayableSection}
                isMobile={isMobile}
                layout={layout}
                dishCardAlignment={dishCardAlignment}
                menuHeaderAlignment={menuHeaderAlignment}
                menuDisplayOptions={menuDisplayOptions}
                last={i === displayableSubMenu.sections.length - 1}
                selectAddressOnDishClick={selectAddressOnDishClick}
                hasItemImages={hasItemImages}
              />
            ))}
          </div>
        ) : (
          <MenuViewEmptyState id={displayableSubMenu.id} />
        )}
      </section>
    </Element>
  );
};

SubMenuView.displayName = 'SubMenuView';

export default SubMenuView;
